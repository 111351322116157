import * as React from "react";
import Masonry from 'react-masonry-css'
import PhotoStack from "../components/PhotoStack"

const FeatureGrid = ({ photoshoots }) => {
  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 1
  };

return (
  <div className="section home">
    <div className="columns">
      <div className="column"></div>
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {photoshoots.map(({node:shoot}) => (
              <PhotoStack link={shoot.fields.slug} title={shoot.frontmatter.title} description={shoot.frontmatter.description} key={shoot.fields.slug} photos={shoot.frontmatter.Photoshoot.photos} mainPhoto={shoot.frontmatter.Photoshoot.photos[0]}/>
            ))}
        </Masonry>


      </div>
    </div>        
  );
}

export default FeatureGrid;
