import * as React from "react";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import Carousel from "./Carousel"
import { Link } from "gatsby";

const PhotoStack = ({ photos, mainPhoto, link, title, description }) => {
    const [show, setShow] = React.useState(false);

    const openModal = () => {
        setShow(true);
      };

    const closeModal = () => {
        setShow(false)
    }
return (
    <>
        <div className="stack-cont">
            {/* <button className="main" onClick={openModal} onKeyDown={openModal}>
                <PreviewCompatibleImage imageInfo={mainPhoto}/>
            </button> */}
            <Link className="main" to={link}>
                <PreviewCompatibleImage imageInfo={mainPhoto}/>
            </Link>
            <div className="stack">
                {photos.slice(1, 5).reverse().map((photo, index) => (
                    <div className="image" key={index}>
                        <PreviewCompatibleImage imageInfo={photo}/>
                    </div>
                ))}
            </div>
        </div>
        {/* {show && <Carousel closeModal={closeModal} photos={photos} link={link} title={title} description={description}/>} */}
    </>
  );
}

export default PhotoStack;
